// custom for wooecommerce


jQuery(function($){
    
    if($('.wooCategory__menu').length > 0){
        $('.wooCategory__menu').owlCarousel({
            margin: 8,
            nav: true,
            dots: false,
            responsiveClass: true,
            loop: false,
            items: 3.5,
            responsive: {
              600:{
                  items: 4.5,
              },
              1000:{
                  items: 6,
              }
            }
        });
        $('.wooCategory__menu__rounditem').matchHeight();
    }

    if($('.woosingle__retailers--more').length > 0){

      $('.woosingle__retailers--more').click(function(){
          

          if($(this).html().toLowerCase().indexOf('more') >= 0) {
            $(this).html($(this).data('less'));
            $('.woosingle__retailer.toggle').slideDown(200);
          } else {
            $(this).html($(this).data('more'));
            $('.woosingle__retailer.toggle').slideUp(200);
          }
      });
    }

    if($('.woosingle__description--button').length > 0){
      
      if ($('.woosingle__description p').length <= 2) {
        $('.woosingle__description--button').hide();  
      } else {
        $('.woosingle__description p').each(function(index){
          if (index >= 2) {
            $(this).addClass('toggle').slideUp();
          }
        })
      }

      $('.woosingle__description--button').click(function(){
          if($(this).html().toLowerCase().indexOf('more') >= 0) {
            $(this).html($(this).data('less'));
            $('.woosingle__description .toggle').slideDown();
          } else {
            $(this).html($(this).data('more'));
            $('.woosingle__description .toggle').slideUp();
          }
      });
    }

    if($('.woosingle__thumb__galleries').length > 0){

      // owlBigImg.trigger('to.owl.carousel', [3, 500]);
      var syncedSecondary = true;
      var slidesPerPage = 1;

      var owlThumbImg = $('.woosingle__thumb__galleries');
      var owlBigImg = $('.woosingle__big__galleries');

      owlBigImg.owlCarousel({
        margin: 0,
        nav: true,
        dots: false,
        loop: true,
        items: 1,
        responsiveRefreshRate: 200,
        lazyLoad: true,
        slideTransition: 'fade',
        responsive: {
          768:{
            nav: false,
          }
        },
        onChanged: function(el) {
          var count = el.item.count - 1;
          var current = Math.round(el.item.index - (el.item.count / 2) - 0.5);
          
          if (current < 0) {
            current = count;
          }
          if (current > count) {
            current = 0;
          }

          owlThumbImg
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");

          var onscreen = owlThumbImg.find(".owl-item.active").length - 1;
          var start = owlThumbImg.find(".owl-item.active").first().index();
          var end = owlThumbImg.find(".owl-item.active").last().index();
          if (current > end) {
            owlThumbImg.trigger('to.owl.carousel', [current, 400]);
          }
          if (current < start) {
            owlThumbImg.trigger('to.owl.carousel', [current - onscreen, 400]);
          }
        }
      });

      owlThumbImg.owlCarousel({
        margin: 8,
        nav: false,
        dots: false,
        responsiveClass: true,
        // loop: false,
        items: 3,
        slideBy: slidesPerPage,
        responsiveRefreshRate : 100,
        lazyLoad: true,
        responsive: {
          600:{
              items: 4,
          },
          1000:{
              items: 6,
          }
        },
        onInitialized: function(event) {
          $(event.target).find('.owl-item').eq(0).addClass('current');
        },
        onChanged: function(event) {
          if (syncedSecondary) {
            var number = event.item.index;
            owlBigImg.data('owl.carousel').to(number, 100, true);
          }
        }
      });


      // prevent when click a tag
      owlThumbImg.on("click", ".owl-item a", function(e) {
        e.preventDefault();
      });

      // thumb scroll when click to item
      owlThumbImg.on("click", ".owl-item", function(e) {
        owlThumbImg.find('.owl-item').removeClass('current');
        $(this).addClass('current');
        var current = $(this).index();
        owlThumbImg.trigger('to.owl.carousel', [current, 400]);
        owlBigImg.trigger('to.owl.carousel', [current, 400]);
      });
    }

    if($('.woosingle__size').length > 0){
        jQuery('.woosingle__size--action ').click(function(){
            var product_id = jQuery(this).data('value');
            
        })
    }

}( jQuery ));